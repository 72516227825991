button:
  about: これは？
  back: 戻る
  go: 進む
  home: ホーム
  toggle_dark: ダークモード切り替え
  toggle_langs: 言語切り替え
intro:
  desc: 固執された Vite スターターテンプレート
  dynamic-route: 動的ルートのデモ
  hi: こんにちは、{name}!
  whats-your-name: 君の名は。
not-found: 見つかりませんでした
